import React from 'react'
import sea from "../Asset/resort/sea9.webp"
import "../css/Events.css"
import siaa from  "../Asset/banner3.jpg"
import siaaa from "../Asset/banner4.jpg"
import Event1 from "../Asset/event/WhatsApp Image 2024-03-23 at 9.34.18 PM.jpeg"
import Event2 from "../Asset/event/WhatsApp Image 2024-03-23 at 9.34.19 PM.jpeg"
import Event3 from "../Asset/event/WhatsApp Image 2024-03-23 at 9.34.20 PM.jpeg"
import Event4 from "../Asset/event/WhatsApp Image 2024-03-23 at 9.34.21 PM.jpeg"
const Event = () => {
  return (
    <div>
              <section
  className="breadcrumb-area d-flex align-items-center"
  style={{ backgroundImage: "url(https://r1imghtlak.ibcdn.com/58b6cb385c9311e7887d0a4cef95d023.jpg?downsize=1920:1080)",backgroundPosition:"center" }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2>Events
</h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Events

                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="rooms-section pb-100 container" >
  <div className="auto-container">
    <div
      className="sec-title text-center wow fadeInUp animated"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
      <span className="sub-title" style={{fontSize:"28px"}}>Event</span>
      <h2  style={{fontSize:"28px"}} >Event</h2>
    </div>
    <div className="row">
    
     
      <div className="room-block col-lg-3 col-md-6 col-sm-12">
        <div
          className="inner-box wow fadeIn animated "
          data-wow-delay="300ms"

          style={{
            visibility: "visible",
            animationDelay: "300ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={Event1} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#"> Event</a>
            </h6>
            <span className="price" style={{color:"white"}}>23/12/2023</span>
          </div>
          <div className="box-caption">
           
           
             <p style={{color:"white"}}>Engaging speakers, workshops, networking, entertainment, exhibits, food, interactive activities, discussions.</p>
           
          </div>
        </div>
      </div>
      <div className="room-block col-lg-3 col-md-6 col-sm-12">
        <div
          className="inner-box wow fadeIn animated"
          data-wow-delay="300ms"
          style={{
            visibility: "visible",
            animationDelay: "300ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={Event2} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#"> Event</a>
            </h6>
            <span className="price" style={{color:"white"}}>1/3/2024</span>
          </div>
          <div className="box-caption">
           
           
             <p style={{color:"white"}}>Engaging speakers, workshops, networking, entertainment, exhibits, food, interactive activities, discussions.</p>
           
          </div>
        </div>
      </div>
      <div className="room-block col-lg-3 col-md-6 col-sm-12">
        <div
          className="inner-box wow fadeIn animated"
          data-wow-delay="300ms"
          style={{
            visibility: "visible",
            animationDelay: "300ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={Event3} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#"> Event</a>
            </h6>
            <span className="price" style={{color:"white"}}>5/3/2024</span>
          </div>
          <div className="box-caption">
           
           
             <p style={{color:"white"}}>Engaging speakers, workshops, networking, entertainment, exhibits, food, interactive activities, discussions.</p>
           
          </div>
        </div>
      </div>
      <div className="room-block col-lg-3 col-md-6 col-sm-12" >
        <div
          className="inner-box wow fadeIn animated"
          data-wow-delay="300ms"
          style={{
            visibility: "visible",
            animationDelay: "300ms",
            animationName: "fadeIn"
          }}
        >
          <div className="image-box">
            <figure className="image-2 overlay-anim">
              <img src={Event4} alt="" />
            </figure>
          </div>
          <div className="content-box">
            <h6 className="title">
              <a href="#"> Event</a>
            </h6>
            <span className="price" style={{color:"white"}}>15/3/2024</span>
          </div>
          <div className="box-caption">
           
           
             <p style={{color:"white"}}>Engaging speakers, workshops, networking, entertainment, exhibits, food, interactive activities, discussions.</p>
           
          </div>
        </div>
      </div>
     
    </div>
  </div>
  <p className='container' style={{textAlign:"justify"}}>
  Escape to the luxurious retreat of Sia Resort, the epitome of elegance and serenity nestled in the heart of Alibaug. As the premier destination for those seeking an unforgettable getaway, Sia Resort stands as the pinnacle of hospitality and relaxation in this picturesque coastal town.

Discover unparalleled comfort and sophistication in our meticulously designed accommodations, each exuding charm and style. Whether you choose a spacious suite or a cozy cottage, every corner of Sia Resort is crafted to provide the utmost comfort and rejuvenation. From breathtaking views of the surrounding landscape to opulent amenities, our resort promises an indulgent experience for every guest.

Indulge in a myriad of activities and experiences tailored to satisfy every whim and desire. Lounge by the pristine poolside, sipping on refreshing cocktails as the warm sun caresses your skin. For adventure seekers, embark on thrilling water sports or explore the rich cultural heritage of Alibaug with guided tours arranged by our knowledgeable staff.

Sia Resort takes pride in its culinary offerings, where each dish is a masterpiece crafted from the freshest local ingredients. From delectable seafood delicacies to international cuisines, our restaurants tantalize the taste buds and leave a lasting impression.

Rejuvenate your senses at our world-class spa, where expert therapists curate personalized treatments to melt away stress and tension. Indulge in holistic therapies and ancient healing techniques, leaving you feeling revitalized and invigorated.

At Sia Resort, every moment is a celebration of luxury and tranquility. Whether you're seeking a romantic escape, a family retreat, or a destination for corporate events, our resort caters to every occasion with unmatched hospitality and attention to detail.

Experience the magic of Alibaug at its finest with a stay at Sia Resort, where luxury knows no bounds and memories are made to last a lifetime. Book your stay today and immerse yourself in the splendor of the best resort in Alibaug.








  </p>
</section>

    </div>
  )
}

export default Event
