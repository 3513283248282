import React from 'react'
import sea from "../Asset/resort/sea9.webp"
import "../css/newryou.css"
import awas from "../Asset/NEARBYATTRECTION/02-Awas-Beach-1.webp"
import kamkar from "../Asset/NEARBYATTRECTION/KAEMARKAR.webp"
import kankesh from "../Asset/NEARBYATTRECTION/Kankeshwar TEMPLE.webp"
import kashidbeach from "../Asset/NEARBYATTRECTION/Kashid-Beach.webp"
import khanderi from "../Asset/NEARBYATTRECTION/KHANDERI ISLAND.webp"
import korlai from "../Asset/NEARBYATTRECTION/korlai.webp"
import kund from "../Asset/NEARBYATTRECTION/MANDWA.webp"
import murud from "../Asset/NEARBYATTRECTION/MURUD BEACH.webp"
import revanda from "../Asset/NEARBYATTRECTION/REVANDA FORT.webp"
import rewas from "../Asset/NEARBYATTRECTION/REWAS JETTY.webp"
import sagargadh from "../Asset/NEARBYATTRECTION/SAGARGAD FORT.webp"
import n from "../Asset/NEARBYATTRECTION/n.jpg"
import birla from "../Asset/NEARBYATTRECTION/birla.jpg"
import naigonbeach from "../Asset/NEARBYATTRECTION/nigonbeach.jpg"
const Thingstodo = () => {
  return (
    <div>
           <section
  className="breadcrumb-area d-flex align-items-center"
  style={{ backgroundImage:"url(https://r1imghtlak.ibcdn.com/58b6cb385c9311e7887d0a4cef95d023.jpg?downsize=1920:1080)",backgroundPosition:"center" }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2>Attraction Point
</h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Attraction Point

                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div className='container'>
<section className="feature-section">
  <div className="auto-container">
    
  <div
      className="row feature-row g-0 wow slideInUp animated"
      data-wow-delay="100ms"
      style={{
        visibility: "visible",
        animationDelay: "100ms",
        animationName: "slideInUp"
      }}
    >
      <div className="image-column col-lg-4">
        <div className="inner-column">
          <div className="image-box">
            <figure className="image overlay-anim">
              <img src={naigonbeach} alt="" />
            </figure>
          </div>
        </div>
      </div>
      <div className="content-column col-lg-8">
        <div className="inner-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title" dfsdf>Nagaon Beach</span>
              {/* <h2>Fitness Center</h2> */}
              <div className="text">
              Nagaon Beach, nestled along the pristine shores, beckons with its golden sands and azure waters. Sun-kissed moments, tranquil waves, and a serene ambiance await adventurers seeking solace and seaside delights.
              </div>
            </div>
            {/* <a
              href="room-details.html"
              className="theme-btn btn-style-one read-more"
            >
              Discover More
            </a> */}
            <figure className="image-2">
              <img src="images/resource/icon.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="200ms"
  style={{
    visibility: "visible",
    animationDelay: "200ms",
    animationName: "slideInUp"
  }}
>
  <div className="content-column col-lg-8 order-md-1 order-2 ">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title" dfsdf>Karmarkar Museum</span>
          {/* <h2>The Restaurant</h2> */}
          <div className="text">
            
Explore history and culture at the Karmarkar Museum. Delve into art, artifacts, and exhibits showcasing the rich heritage of our region. A journey through time awaits your discovery.






          </div>
        </div>
        {/* <a
          href="room-details.html"
          className="theme-btn btn-style-one read-more"
        >
          Discover More
        </a> */}
        <figure className="image-2">
          <img src="images/resource/icon-2.png" alt="" />
        </figure>
      </div>
    </div>
  </div>
  <div className="image-column col-lg-4 order-md-2 order-1">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={kamkar} alt="" />
        </figure>
      </div>
    </div>
  </div>
</div>
<div
      className="row feature-row g-0 wow slideInUp animated"
      data-wow-delay="100ms"
      style={{
        visibility: "visible",
        animationDelay: "100ms",
        animationName: "slideInUp"
      }}
    >
      <div className="image-column col-lg-4">
        <div className="inner-column">
          <div className="image-box">
            <figure className="image overlay-anim">
              <img src={kankesh} alt="" />
            </figure>
          </div>
        </div>
      </div>
      <div className="content-column col-lg-8">
        <div className="inner-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title" dfsdf>Kanakeshwar Devasthan Temple</span>
              {/* <h2>Fitness Center</h2> */}
              <div className="text">
                
Kanakeshwar Devasthan Temple is a sacred site revered by devotees for its spiritual significance. Nestled amidst serene surroundings, it offers solace and a profound connection to divinity.
              </div>
            </div>
            {/* <a
              href="room-details.html"
              className="theme-btn btn-style-one read-more"
            >
              Discover More
            </a> */}
            <figure className="image-2">
              <img src="images/resource/icon.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="200ms"
  style={{
    visibility: "visible",
    animationDelay: "200ms",
    animationName: "slideInUp"
  }}
>
  <div className="content-column col-lg-8 order-md-1 order-2">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title" dfsdf>Birla Temple</span>
          {/* <h2>The Restaurant</h2> */}
          <div className="text">
            
Birla Temple, located in various cities across India, showcases stunning architectural grandeur and serves as a spiritual haven for devotees and visitors alike, offering tranquility and solace amidst bustling urban life.
          </div>
        </div>
        {/* <a
          href="room-details.html"
          className="theme-btn btn-style-one read-more"
        >
          Discover More
        </a> */}
        <figure className="image-2">
          <img src="images/resource/icon-2.png" alt="" />
        </figure>
      </div>
    </div>
  </div>
  <div className="image-column col-lg-4 order-md-2 order-1">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={birla} alt="" />
        </figure>
      </div>
    </div>
  </div>
</div>
<div
      className="row feature-row g-0 wow slideInUp animated"
      data-wow-delay="100ms"
      style={{
        visibility: "visible",
        animationDelay: "100ms",
        animationName: "slideInUp"
      }}
    >
      <div className="image-column col-lg-4">
        <div className="inner-column">
          <div className="image-box">
            <figure className="image overlay-anim">
              <img src={khanderi} alt="" />
            </figure>
          </div>
        </div>
      </div>
      <div className="content-column col-lg-8">
        <div className="inner-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title" dfsdf>Khanderi Island</span>
              {/* <h2>Fitness Center</h2> */}
              <div className="text">
                
Khanderi Island, nestled amidst tranquil waters, boasts pristine beaches and lush greenery. Its rich cultural heritage and diverse marine life attract adventurers and nature enthusiasts alike. Explore its charm and serenity today.
              </div>
            </div>
            {/* <a
              href="room-details.html"
              className="theme-btn btn-style-one read-more"
            >
              Discover More
            </a> */}
            <figure className="image-2">
              <img src="images/resource/icon.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="200ms"
  style={{
    visibility: "visible",
    animationDelay: "200ms",
    animationName: "slideInUp"
  }}
>
  <div className="content-column col-lg-8 order-md-1 order-2">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title" dfsdf>Korlai Beach</span>
          {/* <h2>The Restaurant</h2> */}
          <div className="text">
          Korlai Beach, nestled along the western coast of India, enchants with its pristine shores, azure waters, and serene ambiance, offering visitors a tranquil escape into nature's embrace.
          </div>
        </div>
        {/* <a
          href="room-details.html"
          className="theme-btn btn-style-one read-more"
        >
          Discover More
        </a> */}
        <figure className="image-2">
          <img src="images/resource/icon-2.png" alt="" />
        </figure>
      </div>
    </div>
  </div>
  <div className="image-column col-lg-4 order-md-2 order-1">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={korlai} alt="" />
        </figure>
      </div>
    </div>
  </div>
</div>
<div
      className="row feature-row g-0 wow slideInUp animated"
      data-wow-delay="100ms"
      style={{
        visibility: "visible",
        animationDelay: "100ms",
        animationName: "slideInUp"
      }}
    >
      <div className="image-column col-lg-4">
        <div className="inner-column">
          <div className="image-box">
            <figure className="image overlay-anim">
              <img src={kashidbeach} alt="" />
            </figure>
          </div>
        </div>
      </div>
      <div className="content-column col-lg-8">
        <div className="inner-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title" dfsdf>Kashid Beach</span>
              {/* <h2>Fitness Center</h2> */}
              <div className="text">
              Kashid Beach, nestled along the Arabian Sea coastline, offers pristine sands, azure waters, and scenic vistas. Visitors indulge in sunbathing, water sports, and serene strolls amidst natural splendor.
              </div>
            </div>
            {/* <a
              href="room-details.html"
              className="theme-btn btn-style-one read-more"
            >
              Discover More
            </a> */}
            <figure className="image-2">
              <img src="images/resource/icon.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="200ms"
  style={{
    visibility: "visible",
    animationDelay: "200ms",
    animationName: "slideInUp"
  }}
>
  <div className="content-column col-lg-8 order-md-1 order-2">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title" dfsdf>Murud Beach</span>
          {/* <h2>The Restaurant</h2> */}
          <div className="text">
          Murud Beach is a serene stretch of golden sands, kissed by the gentle waves of the Arabian Sea. Palm trees sway gracefully along the coastline, offering a tranquil escape for weary souls.
          </div>
        </div>
        {/* <a
          href="room-details.html"
          className="theme-btn btn-style-one read-more"
        >
          Discover More
        </a> */}
        <figure className="image-2">
          <img src="images/resource/icon-2.png" alt="" />
        </figure>
      </div>
    </div>
  </div>
  <div className="image-column col-lg-4 order-md-2 order-1">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={murud} alt="" />
        </figure>
      </div>
    </div>
  </div>
</div>
<div
      className="row feature-row g-0 wow slideInUp animated"
      data-wow-delay="100ms"
      style={{
        visibility: "visible",
        animationDelay: "100ms",
        animationName: "slideInUp"
      }}
    >
      <div className="image-column col-lg-4">
        <div className="inner-column">
          <div className="image-box">
            <figure className="image overlay-anim">
              <img src={revanda} alt="" />
            </figure>
          </div>
        </div>
      </div>
      <div className="content-column col-lg-8">
        <div className="inner-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title" dfsdf>Revdanda Fort & Beach</span>
              {/* <h2>Fitness Center</h2> */}
              <div className="text">
              Revdanda Fort stands tall, overlooking the serene beach. History whispers through its ancient walls. Waves caress the shore, a timeless dance. Beauty and heritage unite in this coastal marvel.
              </div>
            </div>
            {/* <a
              href="room-details.html"
              className="theme-btn btn-style-one read-more"
            >
              Discover More
            </a> */}
            <figure className="image-2">
              <img src="images/resource/icon.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="200ms"
  style={{
    visibility: "visible",
    animationDelay: "200ms",
    animationName: "slideInUp"
  }}
>
  <div className="content-column col-lg-8 order-md-1 order-2">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title" dfsdf>Rewas Jetty</span>
          {/* <h2>The Restaurant</h2> */}
          <div className="text">
          Rewas Jetty offers serene views of the shimmering waters, where gentle waves embrace the shore. Visitors revel in tranquility, amidst the whispers of the sea, finding solace in nature's embrace.
          </div>
        </div>
        {/* <a
          href="room-details.html"
          className="theme-btn btn-style-one read-more"
        >
          Discover More
        </a> */}
        <figure className="image-2">
          <img src="images/resource/icon-2.png" alt="" />
        </figure>
      </div>
    </div>
  </div>
  <div className="image-column col-lg-4 order-md-2 order-1">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={rewas} alt="" />
        </figure>
      </div>
    </div>
  </div>
</div>
<div
      className="row feature-row g-0 wow slideInUp animated"
      data-wow-delay="100ms"
      style={{
        visibility: "visible",
        animationDelay: "100ms",
        animationName: "slideInUp"
      }}
    >
      <div className="image-column col-lg-4">
        <div className="inner-column">
          <div className="image-box">
            <figure className="image overlay-anim">
              <img src={sagargadh } alt="" />
            </figure>
          </div>
        </div>
      </div>
      <div className="content-column col-lg-8">
        <div className="inner-column">
          <div className="content-box">
            <div className="sec-title">
              <span className="sub-title" dfsdf>Sagargad Fort</span>
              {/* <h2>Fitness Center</h2> */}
              <div className="text">
                
Sagargad Fort, nestled amidst nature's embrace, stands tall with tales untold. History echoes through its ancient walls, inviting adventurers to explore its rugged terrain and unravel its secrets.
              </div>
            </div>
            {/* <a
              href="room-details.html"
              className="theme-btn btn-style-one read-more"
            >
              Discover More
            </a> */}
            <figure className="image-2">
              <img src="images/resource/icon.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
    
   

  
  
  </div>
</section>
  </div>
    </div>
  )
}

export default Thingstodo
