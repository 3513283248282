
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
import lightGallery from "lightgallery";
import sea from "../Asset/resort/sea9.webp"
const Gallery = () => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  return (
    <div>
      <section
  className="breadcrumb-area d-flex align-items-center"
  style={{ backgroundImage:"url(https://r1imghtlak.ibcdn.com/58b6cb385c9311e7887d0a4cef95d023.jpg?downsize=1920:1080)",backgroundPosition:"center" }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2>Gallery</h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Gallery
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div style={{paddingTop:"20px"}}>
<div className="container">
          <div id="image-gallery" className="row">
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              href="https://www.siaresort.com/img/Thing-to-do/nagaon-01.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://www.siaresort.com/img/Thing-to-do/nagaon-01.jpg"
            >
              <img
                src="https://www.siaresort.com/img/Thing-to-do/nagaon-01.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://www.siaresort.com/img/Thing-to-do/murud-01.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://www.siaresort.com/img/Thing-to-do/murud-01.jpg"
            >
              <img
                src="https://www.siaresort.com/img/Thing-to-do/murud-01.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 1"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://www.siaresort.com/img/Thing-to-do/kandheri-02.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://www.siaresort.com/img/Thing-to-do/kandheri-02.jpg"
            >
              <img
                src="https://www.siaresort.com/img/Thing-to-do/kandheri-02.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://www.siaresort.com/img/Thing-to-do/birla-temple-01.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://www.siaresort.com/img/Thing-to-do/birla-temple-01.jpg"
            >
              <img
                src="https://www.siaresort.com/img/Thing-to-do/birla-temple-01.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://www.siaresort.com/img/Thing-to-do/kolaba-03.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://www.siaresort.com/img/Thing-to-do/kolaba-03.jpg"
            >
              <img
                src="https://www.siaresort.com/img/Thing-to-do/kolaba-03.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href="https://www.siaresort.com/img/Thing-to-do/kanakeshwar-03.jpg"
              data-lg-size="1600-2400"
              data-lg-srcset="https://www.siaresort.com/img/Thing-to-do/kanakeshwar-03.jpg"
            >
              <img
                src="https://www.siaresort.com/img/Thing-to-do/kanakeshwar-03.jpg"
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
          </div>
          <p style={{textAlign:"justify"}}>
Experience the epitome of luxury and tranquility at our exquisite resort near Alibaug Beach. Nestled amidst the serene coastal beauty of Alibaug, our resort offers a haven of relaxation and rejuvenation for discerning travelers.

As you step into our resort, you are greeted by the soothing ambiance of lush greenery and the gentle breeze from the Arabian Sea. Our meticulously designed accommodations provide the perfect blend of modern comfort and traditional charm, ensuring a truly unforgettable stay. Whether you choose a spacious room or a luxurious villa, every corner exudes elegance and sophistication.

Indulge in a myriad of recreational activities during your stay at our resort near Alibaug Beach. Dive into our sparkling swimming pool for a refreshing swim or bask in the sun on our sun loungers overlooking the azure waters. For adventure enthusiasts, we offer thrilling water sports such as jet skiing, parasailing, and banana boat rides, promising an adrenaline-packed experience like no other.

Savor delectable culinary delights at our onsite restaurants, where skilled chefs craft mouthwatering dishes using the freshest local ingredients. From authentic coastal cuisine to international delicacies, there's something to tantalize every palate. After a day of exploration, unwind with a signature cocktail at our stylish bar, where you can enjoy panoramic views of the sunset painting the sky in hues of orange and pink.

Pamper your senses at our spa, where ancient healing techniques and modern therapies converge to offer blissful relaxation. Indulge in rejuvenating massages, invigorating body scrubs, and holistic wellness treatments designed to soothe the mind, body, and soul.

With its unparalleled beauty and world-class amenities, our resort near Alibaug Beach is the perfect destination for romantic getaways, family vacations, and corporate retreats alike. Come experience the magic of Alibaug with us and create memories that will last a lifetime.
</p>
        </div>
    
</div>




    </div>
  )
}

export default Gallery
