import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import '../css/MoDal.css';
import $ from "jquery";
import Form from 'react-bootstrap/Form';

const MailPop = (props) => {

  const { onHide } = props;
  // email
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [adults, setAdults] = useState("");
  const [room, setRoom] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your form submission logic here

    // Reset form fields
    setCustName("");
    setCustEmail("");
    setCustContact("");
    setCustSubject("");
    setCustMessage("");
    setCheckInDate("");
    setCheckOutDate("");
    setAdults("");
    setRoom("");

    onHide(); // Close the modal
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Enquiry now
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popMail">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Name"
                  autoFocus
                  value={custName}
                  onChange={(e) => setCustName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  autoFocus
                  value={custEmail}
                  onChange={(e) => setCustEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Enter Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="+123456789"
                  autoFocus
                  value={custContact}
                  onChange={(e) => setCustContact(e.target.value)}
                />
              </Form.Group>
              
              <Form.Group className="mb-3" controlId="checkInDate">
                <Form.Label>Check In Date</Form.Label>
                <Form.Control
                  type="date"
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="checkOutDate">
                <Form.Label>Check Out Date</Form.Label>
                <Form.Control
                  type="date"
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="adults">
                <Form.Label>Adults</Form.Label>
                <Form.Control
                  as="select"
                  value={adults}
                  onChange={(e) => setAdults(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="room">
                <Form.Label>Room</Form.Label>
                <Form.Control
                  as="select"
                  value={room}
                  onChange={(e) => setRoom(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </Form.Control>
              </Form.Group>
              <Button type="submit">Submit</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MailPop;
