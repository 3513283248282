import React from 'react'
import I1 from "../Asset/HOMEICON/l1.png"
import I2 from "../Asset/HOMEICON/I2.png"
import I3 from "../Asset/HOMEICON/I3.png"
import I4 from "../Asset/HOMEICON/I4.png"
import I5 from "../Asset/HOMEICON/I5.png"
import I6 from "../Asset/HOMEICON/I6.png"
import sea from "../Asset/resort/sea9.webp"
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import rroms from  "../Asset/coze/Cozy Villa (1bhk)_page-0008.jpg"
import coze from "../Asset/grand/img52.jpg"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const Rooms = () => {
  return (
    <div>
      <section
  className="breadcrumb-area d-flex align-items-center"
  style={{ backgroundImage: "url(https://r1imghtlak.ibcdn.com/58b6cb385c9311e7887d0a4cef95d023.jpg?downsize=1920:1080)",backgroundPosition:"center" }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2>Accomdation</h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Accomdation
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="services" className="services-area pt-120 pb-90">
  <div className="container">
    <div className="row">
    <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                   
                    <SwiperSlide>
    <div
            className="slick-slide slick-cloned"
            data-slick-index={-3}
            id=""
            aria-hidden="true"
            tabIndex={-1}
            // style={{ width: 440 }}
          >
            <div className="single-services mb-30">
              <div className="services-thumb">
                <a
                  className="gallery-link popup-image"
                  href="#"
                  tabIndex={-1}
                >
                  <img src={rroms} alt="img" style={{height:"312px"}} />
                </a>
              </div>
              <div className="services-content">
                <div className="day-book">
                  <ul style={{textAlign:"center"}}>
                   
                    <li>
                      <a href="/ROOMSdet" tabIndex={-1}>
                        Book Now
                      </a>

                    </li>
                  </ul>
                </div>
                <h4>
                  <a href="#" tabIndex={-1}>
                  Deluxe Room
                  </a>
                </h4>
                <p>
                Indulge in luxury with our exquisite deluxe room, perfect for ultimate relaxation.
                </p>
                <div className="icon">
                  <ul>
                    <li>
                      <img src={I1} alt="img" />
                    </li>
                    <li>
                      <img src={I2} alt="img" />
                    </li>
                    <li>
                      <img src={I3} alt="img" />
                    </li>
                    <li>
                      <img src={I4} alt="img" />
                    </li>
                    <li>
                      <img src={I5} alt="img" />
                    </li>
                    <li>
                      <img src={I6} alt="img" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div
            className="slick-slide slick-cloned"
            data-slick-index={-3}
            id=""
            aria-hidden="true"
            tabIndex={-1}
            // style={{ width: 440 }}
          >
            <div className="single-services mb-30">
              <div className="services-thumb">
                <a
                  className="gallery-link popup-image"
                  href="#"
                  tabIndex={-1}

                >
                  <img src={coze} alt="img" style={{height:"312px"}} />
                </a>
              </div>
              <div className="services-content">
                <div className="day-book">
                  <ul style={{textAlign:"center"}}>
                    
                    <li>
                      <a href="/Roomdetailcoze" tabIndex={-1}>
                        Book Now
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  <a href="#" tabIndex={-1}>
                  Cozy Villa
                  </a>
                </h4>
                <p>
                Tranquil retreat nestled amidst nature's embrace, offering serenity and comfort.
                </p>
                <div className="icon">
                  <ul>
                    <li>
                      <img src={I1} alt="img" />
                    </li>
                    <li>
                      <img src={I2} alt="img" />
                    </li>
                    <li>
                      <img src={I3} alt="img" />
                    </li>
                    <li>
                      <img src={I4} alt="img" />
                    </li>
                    <li>
                      <img src={I5} alt="img" />
                    </li>
                    <li>
                      <img src={I6} alt="img" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div
            className="slick-slide slick-cloned"
            data-slick-index={-3}
            id=""
            aria-hidden="true"
            tabIndex={-1}
            // style={{ width: 440 }}
          >
            <div className="single-services mb-30">
              <div className="services-thumb">
                <a
                  className="gallery-link popup-image"
                  href="#"
                  tabIndex={-1}
                >
                  <img src="https://www.siaresort.com/img/accomodation/grand-6.jpg" alt="img" style={{height:"312px"}} />
                </a>
              </div>
              <div className="services-content">
                <div className="day-book">
                  <ul style={{textAlign:"center"}}>
                   
                    <li>
                      <a href="/GRANDVILA" tabIndex={-1}>
                        Book Now
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  <a href="#" tabIndex={-1}>
                  Grand Villa
                  </a>
                </h4>
                <p>
                Luxurious retreat, where opulence meets comfort in serene surroundings. 
                </p>
                <div className="icon">
                  <ul>
                    <li>
                      <img src={I1} alt="img" />
                    </li>
                    <li>
                      <img src={I2} alt="img" />
                    </li>
                    <li>
                      <img src={I3} alt="img" />
                    </li>
                    <li>
                      <img src={I4} alt="img" />
                    </li>
                    <li>
                      <img src={I5} alt="img" />
                    </li>
                    <li>
                      <img src={I6} alt="img" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </SwiperSlide>
          </Swiper>
    </div>
  </div>
</section>



 
    </div>
  )
}

export default Rooms
