import React from 'react'
import hotel from "../Asset/hotel.png"
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import backgroundfood from "../Asset/food/background.jpg"
import sunset from "../Asset/sunset.png"
import threestar from "../Asset/3-stars.png"
import { BiFoodTag } from "react-icons/bi";
import parkingaera from "../Asset/parking-area.png"
import barcounter from "../Asset/bar-counter.png"
import pool from "../Asset/swimming-pool.png"
import sea3 from "../Asset/resort/sea.webp"
import sea from "../Asset/resort/sea9.webp"
import games from "../Asset/games.png"
import krokio from "../Asset/kriko.png"
import ture from "../Asset/turegide.png"
import bornfire from "../Asset/bornfiree.png"
import buttergarlic from "../Asset/food/Butter garlic crab.jpeg"
import crabsmasala from "../Asset/food/Crab masala.jpeg"
import clabsplatter from "../Asset/food/Crab platter.jpg"
import pomfretgrilled from "../Asset/food/Pomfret grilled.jpg"
import porfedtandoor from "../Asset/food/Pomfret tandoori.jpg"
import greensalad from "../Asset/food/greensalad.jpg"
import tandoorrawas from "../Asset/food/Tandoori rawas.jpeg"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const Services = () => {
  return (
    <div>
      <section
  className="breadcrumb-area d-flex align-items-center"
  style={{ backgroundImage: "url(https://r1imghtlak.ibcdn.com/58b6cb385c9311e7887d0a4cef95d023.jpg?downsize=1920:1080)",backgroundPosition:"center" }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2>Service</h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Service
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="service-details2" className="pt-120 pb-90 p-relative">
  <div className="animations-01">
   
  </div>
  <div className="container">
    <div className="row align-items-center">
    <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                   
                    <SwiperSlide>
    <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={hotel} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={hotel} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#"> Quality Room</a>
            </h3>
            <p>
            Exquisite ambiance, plush amenities, tranquil setting; perfect for relaxation and rejuvenation.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
        <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={threestar} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={threestar} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Best Accommodation</a>
            </h3>
            <p>
            Exquisite lodging for tranquil relaxation and rejuvenating comfort awaits you.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={parkingaera} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={parkingaera} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Parking </a>
            </h3>
            <p>
            Reserved parking space available for tenants and visitors. Inquire within.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={barcounter} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={barcounter} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Restaurants &amp; Bars</a>
            </h3>
            <p>
            Discover exquisite dining experiences at our renowned restaurants & bars.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={pool} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={pool} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Swimming Pool</a>
            </h3>
            <p>
            Swimming pool shimmered invitingly under the scorching summer sun rays.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={games} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={games} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Games</a>
            </h3>
            <p>
            Explore vast worlds, conquer foes, master skills: Gaming adventures await.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={bornfire} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={bornfire} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">BornFire</a>
            </h3>
            <p>
            BornFire blazes, igniting passion in hearts, fueling dreams to soar.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={krokio} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={krokio} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Karaoke</a>
            </h3>
            <p>
            Sing along with friends, unleash joy, music fills the night.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="">
        <div className="services-08-item mb-30">
          <div className="services-icon2">
            <img src={ture} alt="img" />
          </div>
          <div className="services-08-thumb">
            <img src={ture} alt="img" />
          </div>
          <div className="services-08-content">
            <h3>
              <a href="#">Tour Guide</a>
            </h3>
            <p>
            Explore vibrant sights, taste local flavors, embrace culture. Your journey awaits.
            </p>
            
          </div>
        </div>
      </div>
      </SwiperSlide>
          </Swiper>
    </div>
  </div>
</section>
{/* <section
  className="feature-area2 p-relative fix"
  style={{ background: "#f7f5f1" }}
>

  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
        <div className="feature-img">
          <img src="https://www.siaresort.com/img/accomodation/cozy-1.jpg" alt="img" className="img" style={{width:"948px"}} />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div className="feature-content s-about-content">
          <div className="feature-title pb-20">
            <h5>Luxury Resort</h5>
            <h2>Pearl Of The Adriatic.</h2>
          </div>
          <p>
            Vestibulum non ornare nunc. Maecenas a metus in est iaculis pretium.
            Aliquam ullamcorper nibh lacus, ac suscipit ipsum consequat
            porttitor.Aenean vehicula ligula eu rhoncus porttitor. Duis vel
            lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at.
          </p>
          <p>
            Cras finibus laoreet felis et hendrerit. Integer ligula lorem,
            finibus vitae lorem at, egestas consectetur urna. Integer id
            ultricies elit. Maecenas sodales nibh, quis posuere felis. In
            commodo mi lectus venenatis metus eget fringilla. Suspendisse varius
            ante eget.
          </p>
          <div className="slider-btn mt-15">
            <a href="about.html" className="btn ss-btn smoth-scroll">
              Discover More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}
{/* <section className="booking pt-120 pb-120 p-relative fix">
  <div className="animations-01">
   
  </div>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6 col-md-6">
        <div className="contact-bg02">
          <div className="section-title center-align">
            <h5>make appointment</h5>
            <h2>Book A Room</h2>
          </div>
          <form action="mail.php" method="post" className="contact-form mt-30">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="contact-field p-relative c-name mb-20">
                  <label>
                    <i className="fal fa-badge-check" /> Check In Date
                  </label>
                  <input type="date" id="chackin" name="date" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="contact-field p-relative c-subject mb-20">
                  <label>
                    <i className="fal fa-times-octagon" /> Check Out Date
                  </label>
                  <input type="date" id="chackout" name="date" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="contact-field p-relative c-subject mb-20">
                  <label>
                    <i className="fal fa-users" /> Adults
                  </label>
                  <select name="adults" id="adu">
                    <option value="sports-massage">Adults</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="contact-field p-relative c-option mb-20">
                  <label>
                    <i className="fal fa-concierge-bell" /> Room
                  </label>
                  <select name="room" id="rm">
                    <option value="sports-massage">Room</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="slider-btn mt-15">
                  <button
                    className="btn ss-btn"
                    data-animation="fadeInRight"
                    data-delay=".8s"
                  >
                    <span>Book Table Now</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className="booking-img">
        <img src={sea3} alt="img" style={{height:"572px"}} />
        </div>
      </div>
    </div>
  </div>
</section> */}
   <section
        className="testimonial-area pt-120 pb-90 p-relative fix repetefood"
        style={{
          // backgroundImage: `url(${backgroundfood})`,
          background:` linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5) 10%), url(${backgroundfood})`,
         

        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title center-align mb-50 text-center">
                <h5>Foods</h5>
                <h2>Foods</h2>
                <p style={{color:"black"}}>
                Savor the rich flavors, indulge in culinary delights, and experience pure gastronomic bliss with every bite. Bon appétit!
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="testimonial-active slick-initialized slick-slider slick-dotted">
                <div className="slick-list draggable">
                  <div className="slick-track" style={{}}>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                      },
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                   
                    <SwiperSlide>
                      {" "}
                      <div
                        className="owl-item cloned"
                        style={{ width: "100%", marginRight: 45 }}
                      >
                        <div className="offer-block-two margin-top">
                          <div className="inner-box">
                            <div className="image">
                              <a href="#">
                                <img
                                  src={buttergarlic}
                                  className="imagefood" 
                                  alt=""
                                />
                              </a>
                            </div>
                            <h4 className="foodtext">
                              <a href="menu-list-2.html">Butter garlic crab <BiFoodTag style={{color:"red"}} /></a>
                            </h4>
                            <div className="text desc textpera" style={{color:"black"}}>
                              
Delicious butter garlic crab a savory delight for seafood enthusiasts.
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="owl-item cloned"
                        style={{ width: "100%", marginRight: 45 }}
                      >
                        <div className="offer-block-two">
                          <div className="inner-box">
                            <div className="image">
                              <a href="#">
                                <img
                                  src={crabsmasala}
                                  className="imagefood"
                                  alt=""
                                />
                              </a>
                            </div>
                            <h4 className="foodtext">
                              <a href="menu-list-3.html">Crab masala  <BiFoodTag style={{color:"red"}} /></a>
                            </h4>
                           <div className="text desc textpera" style={{color:"black"}}>
                           Delicious crab masala bursting with flavors, a culinary delight awaits.
                            </div>
                            {/* <div className="price">$15.00</div> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      {" "}
                      <div
                        className="owl-item cloned"
                        style={{ width: "100%", marginRight: 45 }}
                      >
                        <div className="offer-block-two margin-top">
                          <div className="inner-box">
                            <div className="image">
                              <a href="#">
                                <img
                                  src={clabsplatter}
                                  className="imagefood"
                                  alt=""
                                />
                              </a>
                            </div>
                            <h4 className="foodtext">
                              <a href="menu-list-2.html">Crab platter <BiFoodTag style={{color:"red"}} /></a>
                            </h4>
                            <div className="text desc textpera" style={{color:"black"}}>
                            Indulge in our sumptuous crab platter, a seafood lover's delight!
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="owl-item cloned"
                        style={{ width: "100%", marginRight: 45 }}
                      >
                        <div className="offer-block-two">
                          <div className="inner-box">
                            <div className="image">
                              <a href="#">
                                <img
                                  src={pomfretgrilled}
                                  className="imagefood"
                                  alt=""
                                />
                              </a>
                            </div>
                            <h4 className="foodtext">
                              <a href="menu-list-3.html">Pomfret grilled  <BiFoodTag style={{color:"red"}} /></a>
                            </h4>
                           <div className="text desc textpera" style={{color:"black"}}>
                           Juicy Pomfret grilled to perfection, bursting with flavor and succulence.
                            </div>
                            {/* <div className="price">$15.00</div> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      {" "}
                      <div
                        className="owl-item cloned"
                        style={{ width: "100%", marginRight: 45 }}
                      >
                        <div className="offer-block-two margin-top">
                          <div className="inner-box">
                            <div className="image">
                              <a href="#">
                                <img
                                  src={porfedtandoor}
                                  className="imagefood"
                                  alt=""
                                />
                              </a>
                            </div>
                            <h4 className="foodtext">
                              <a href="menu-list-2.html">Pomfret tandoori <BiFoodTag style={{color:"red"}} /></a>
                            </h4>
                            <div className="text desc textpera" style={{color:"black"}}>
                            Flavorful Pomfret Tandoori: Grilled perfection with a tantalizing spice blend.
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="owl-item cloned"
                        style={{ width: "100%", marginRight: 45 }}
                      >
                        <div className="offer-block-two">
                          <div className="inner-box">
                            <div className="image">
                              <a href="#">
                                <img
                                  src={greensalad}
                                  className="imagefood"
                                  alt=""
                                />
                              </a>
                            </div>
                            <h4 className="foodtext">
                              <a href="menu-list-3.html">Green Salad <BiFoodTag style={{color:"green"}} /></a>
                            </h4>
                           <div className="text desc textpera" style={{color:"black"}}>
                           Fresh greens, crunchy veggies, tossed with zesty vinaigrette. Healthy indulgence.  
                            </div>
                            {/* <div className="price">$15.00</div> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="owl-item cloned"
                        style={{ width: "100%", marginRight: 45 }}
                      >
                        <div className="offer-block-two">
                          <div className="inner-box">
                            <div className="image">
                              <a href="#">
                                <img
                                  src={tandoorrawas}
                                  className="imagefood"
                                  alt=""
                                />
                              </a>
                            </div>
                            <h4 className="foodtext">
                              <a href="menu-list-3.html">Tandoori rawas  <BiFoodTag style={{color:"red"}} /></a>
                            </h4>
                           <div className="text desc textpera textpera" style={{color:"black"}}>
                           Succulent Tandoori Rawas: A Flavourful Delight for Seafood Connoisseurs!
                            </div>
                            {/* <div className="price">$15.00</div> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  
                  </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Services
