import React from 'react'
import { RiHotelBedLine } from "react-icons/ri";
import { PiTelevisionSimple } from "react-icons/pi";
import { FaWifi } from "react-icons/fa6";
import { TbAirConditioning } from "react-icons/tb";
import { PiThermometerHotBold } from "react-icons/pi";
import { PiPhoneDisconnectDuotone } from "react-icons/pi";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { MdPersonOutline } from "react-icons/md";
import { IoIosResize } from "react-icons/io";
import sea from "../Asset/resort/sea9.webp"
const RoomDetails = () => {
  return (
    <div>
      <section
  className="breadcrumb-area d-flex align-items-center"
  style={{
    backgroundImage:
    "url(https://r1imghtlak.ibcdn.com/58b6cb385c9311e7887d0a4cef95d023.jpg?downsize=1920:1080)",backgroundPosition:"center"
  }}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2>Room Deatils</h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Services Deatils
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div className="about-area5 about-p p-relative">
  <div className="container pt-120 pb-40">
    <div className="row">
      {/* #right side */}
      <div className="col-sm-12 col-md-12 col-lg-4 order-2">
        <aside className="sidebar services-sidebar">
          {/* Category Widget */}
          <div className="sidebar-widget categories">
            <div className="widget-content">
              <h2 className="widget-title"> Book A Room</h2>
              {/* Services Category */}
              {/* booking-area */}
              <div className="booking">
                <div className="contact-bg">
                  <form
                    action="mail.php"
                    method="post"
                    className="contact-form mt-30"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-name mb-20">
                          <label>
                            <i className="fal fa-badge-check" /> Check In Date
                          </label>
                          <input type="date" id="chackin" name="date" />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-20">
                          <label>
                            <i className="fal fa-times-octagon" /> Check Out
                            Date
                          </label>
                          <input type="date" id="chackout" name="date" />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-20">
                          <label>
                            <i className="fal fa-users" /> Adults
                          </label>
                          <select name="adults" id="adu">
                            <option value="sports-massage">Adults</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-option mb-20">
                          <label>
                            <i className="fal fa-concierge-bell" /> Room
                          </label>
                          <select name="room" id="rm">
                            <option value="sports-massage">Room</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="slider-btn mt-15">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                          >
                            <span>Book Table Now</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* booking-area-end */}
            </div>
          </div>
          {/*Service Contact*/}
          <div
            className="service-detail-contact wow fadeup-animation animated"
            data-wow-delay="1.1s"
            style={{ visibility: "visible", animationDelay: "1.1s" }}
          >
            <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
            <a href="javascript:void(0);" title="Call now">
              +91 9404100001
            </a>
          </div>
        </aside>
      </div>
      {/* #right side end */}
      <div className="col-lg-8 col-md-12 col-sm-12 order-1">
        <div className="service-detail">
          {/* Two Column */}
          <div className="two-column">
            <div className="row">
              <div className="image-column col-xl-12 col-lg-12 col-md-12">
                <figure className="image">
                  <img src="https://www.siaresort.com/img/accomodation/deluxe-1.jpg" alt="" />
                </figure>
              </div>
             
            </div>
          </div>
          <div className="content-box">
            <div className="row align-items-center mb-50">
              <div className="col-lg-6 col-md-6">
                <div className="price">
                  <h2>Deluxe Room</h2>
                  
                </div>
              </div>
              <div className="col-lg-6 text-right">
                <img src="https://htmldemo.zcubethemes.com/riorelax/img/testimonial/review-icon.png " alt="img" />
              </div>
            </div>
            <p>
            Escape to a haven of tranquility and luxury with our deluxe rooms in Alibaug resort. Perfectly designed for couples or small families of 2+1, our accommodations redefine comfort and relaxation. From the moment you step inside, you'll be enveloped in an atmosphere of serenity, with spacious interiors, a warm and inviting ambiance, and tasteful furnishings that exude elegance.

Each deluxe room offers a panoramic view of the resort's mesmerizing swimming pool and the verdant green landscapes that surround it, creating a picturesque backdrop for your stay. Whether you're lounging on the plush double bed, catching up on work at the convenient study table, or storing your belongings in the ample wardrobe space, every aspect of our rooms is meticulously designed to ensure your utmost comfort and satisfaction.
            </p>
            <p>
            In addition to the luxurious amenities provided, such as air conditioning to keep you cool on warm days and nights, an LED TV with live satellite entertainment for your viewing pleasure, and spacious open bathrooms where you can unwind after a day of exploring, our resort also offers a range of facilities and services to enhance your stay.

Take a leisurely stroll through our beautifully landscaped gardens, indulge in a rejuvenating spa treatment, or enjoy a refreshing dip in the pool. For those seeking culinary delights, our onsite restaurant serves up a delectable array of local and international dishes, carefully prepared to tantalize your taste buds.
            </p>
            <h3>Room Feature.</h3>
            <ul className="room-features d-flex align-items-center">
              <li>
                <i className='fal'>
                <PiTelevisionSimple />

                </i>
                <i className="fal fa-tv-retro" /> TV

              </li>
              <li>
                <i className='fal'>
                <FaWifi />
                </i>
               Free Wifi
              </li>
              <li>
                <i className='fal'>
                <TbAirConditioning />
                </i>
                 Air Condition
              </li>
              <li>
                <i className='fal'>  <PiThermometerHotBold /></i>
                Heater
              </li>
              <li>
                <i className='fal'>
                < PiPhoneDisconnectDuotone />

                </i>

                 Phone
              </li>
              <li>
             
              <i className='fal'> <MdOutlineLocalLaundryService /></i>
                 Laundry
              </li>
              <li>
                <i className='fal'> <MdPersonOutline /></i>
                Adults: 2
              </li>
              <li>
              <i className='fal'><IoIosResize /></i>
                Size: 24m²
              </li>
              <li>
                <i className='fal'><RiHotelBedLine /></i>
            Bed Type: One bed
              </li>
            </ul>
            <h3>Children and extra beds.</h3>
            <p>
            Children are warmly welcomed at our hotel, where they stay free using existing bedding. While complimentary breakfast may not be included for children, we do offer rollaway or extra beds upon request. Our priority is ensuring families have a comfortable and memorable stay with us, providing convenience and care throughout their visit.
            </p>
            <div className="mb-50">
              <a href="contact.html" className="btn ss-btn">
                Book This Room
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default RoomDetails
