import React from 'react'
import { IoMapOutline } from "react-icons/io5";
import { CiClock2 } from "react-icons/ci";
import { IoMailOutline } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import logo from "../Asset/logo.png"
const Footer = () => {
  return (
    <div>
      <footer className="footer-bg footer-p">
  <div
    className="footer-top  pt-90 pb-40"
    style={{
      backgroundColor: "#644222",
      backgroundImage: "url(https://htmldemo.zcubethemes.com/riorelax/img/bg/footer-bg.png)"
    }}
  >
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-xl-4 col-lg-4 col-sm-6">
          <div className="footer-widget mb-30">
            <div className="f-widget-title mb-30">
              <img src={logo} alt="img" />
            </div>
            <div className="f-contact">
              <ul>
                <li>
                 
                  <i className='icon fal fa-phone'>  <IoCall /></i>
                  <span>
                 +91-9404100001
                   
                  </span>
                </li>
                <li>
                <i className='icon fal fa-phone'>   <  IoMailOutline /></i>
                  <span>
                    <a href="mailto:info@example.com">sales@siaresort.com</a>

                  </span>
                </li>
                <li>
                <i className='icon fal fa-phone'>   <IoMapOutline /></i>
                  <span>
                    
Survey 1915, Chintamani Galli (Lane),
<br />
Nagaon Beach Road,Nagaon, Alibag.
                   
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-sm-6">
          <div className="footer-widget mb-30">
            <div className="f-widget-title">
              <h2>Our Links</h2>
            </div>
            <div className="footer-link">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
               
                <li>
                <a href="/aboutus">About</a>
                </li>
                <li>
                <a href="/ROOMS">Accomdation</a>
                </li>
                <li>
                <a href="/Service">Services</a>
                </li>
                <li>
                <a href="/Gallery">Gallery</a>
                </li>
                <li>
                <a href="/THINGDTODO">Attraction Point</a>
                </li>
                <li>
                <a href="/Contectus">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
       
        <div className="col-xl-4 col-lg-4 col-sm-6">
          <div className="footer-widget mb-30">
            <div className="f-widget-title">
              <h2>Location</h2>
            </div>
            <div className="footer-link">
            <div style={{ borderRadius: 25 }}>
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20542.53156677919!2d72.90656253119111!3d18.606385266904052!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be87074ff93d0fb%3A0x8f93072634bd7951!2sSia%20Resort!5e0!3m2!1sen!2sin!4v1708779429906!5m2!1sen!2sin"
    style={{ borderRadius: 25 }}
  />
</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-wrap">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-6">
          Copyright © sia resort 2024 . All rights reserved.
        </div>
        {/* <div className="col-lg-6 col-md-6 text-right text-xl-right">
          <div className="footer-social">
            <a href="#">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#">
              <i className="fab fa-twitter" />
            </a>
            <a href="#">
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
