
import './App.css';
import Footer from './components/Footer';
import Hadder from './components/Hadder';
import Aboutus from './pages/Aboutus';
import Contectus from './pages/Contectus';
import Home from './pages/Home';
import RoomDetails from './pages/RoomDetails';
import 'animate.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Rooms from './pages/Rooms';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Thingstodo from './pages/Thingstodo';
import Roomdetaicoze from './pages/Roomdetaicoze';
import GrandVilla from './pages/GrandVilla';
import Event from './pages/Event';
import "../src/css/responsive.css"
function App() {
  return (
  <>



  
 

<BrowserRouter> 
      <Hadder/>
      
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/aboutus' element={<Aboutus/>}></Route>
     <Route path='/Contectus' element={<Contectus/>}></Route>
     <Route path='/Service' element={<Services/>}></Route>
     <Route path='/ROOMS' element={<Rooms/>}></Route>
     <Route path='/Gallery' element={<Gallery/>}></Route>
     <Route path='/THINGDTODO' element={<Thingstodo/>}></Route>
     <Route path='/Roomdetailcoze' element={<Roomdetaicoze/>}></Route>
     <Route path='/ROOMSdet' element={<RoomDetails/>}></Route>
     <Route path='/GRANDVILA' element={<GrandVilla/>}></Route>
     <Route path='/Event' element={<Event/>}></Route>

      
     
     {/* {
            Servicedata.map((route, index) => {
              console.log("route.slug",route.slug)
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Servicedetil/>}
                  />
                </>
              )
            })
          } */}
      </Routes>
<Footer/>
    </BrowserRouter>
  </>
  );
}

export default App;
