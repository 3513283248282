import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import banner from "../Asset/bannerneww.jpg"
import banner2 from "../Asset/SIABANNER2.jpg"
import banner3 from "../Asset/banner3.jpg"
import banner4 from "../Asset/banner4.jpg"

// import required modules
import {  Pagination, Navigation } from 'swiper/modules';
const Slider = () => {
  return (
    <div>
        <Swiper
        spaceBetween={30}
        centeredSlides={true}
        // autoplay={{
        //   delay: 4500,
        //   disableOnInteraction: false,
        // }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
   

  

{/* <SwiperSlide>   <section id="home" className="slider-area fix p-relative">
  <div
    className="slider-active slick-initialized slick-slider"
    style={{ background: "#101010" }}
  >
    <button type="button" className="slick-prev slick-arrow" style={{}}>
      <i className="far fa-angle-left" />
    </button>
    <div className="slick-list draggable">
      <div className="slick-track" style={{ opacity: 1 }}>
        <div
          className="single-slider slider-bg d-flex align-items-center slick-slide slick-current slick-active"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            
            position: "relative",
            backgroundPosition:"unset",
            left: 0,
            top: 0,
            zIndex: 999,
            opacity: 1
          }}
          data-slick-index={0}
          aria-hidden="false"
          tabIndex={0}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-7">
                <div className="slider-content s-slider-content mt-80 text-center">
                  <h2
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className="animate__animated animate__bounceIn"
                    style={{ animationDelay: "0.4s" }}
                  >
                    Enjoy A Luxuary Experience
                  </h2>
                  <p
                    data-animation="fadeInUp"
                    data-delay=".6s"
                    className=""
                    style={{ animationDelay: "0.6s" }}
                  >
                    Donec vitae libero non enim placerat eleifend aliquam erat
                    volutpat. Curabitur diam ex, dapibus purus sapien, cursus
                    sed nisl tristique, commodo gravida lectus non.
                  </p>
                  <div className="slider-btn mt-30 mb-105">
                    <a
                      href="/aboutus"
                      className="btn ss-btn active mr-15"
                      data-animation="fadeInLeft"
                      data-delay=".4s"
                      tabIndex={0}
                      style={{ animationDelay: "0.4s" }}
                    >
                      Discover More{" "}
                    </a>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</section></SwiperSlide> */}
<SwiperSlide>     <section id="home" className="slider-area fix p-relative">
  <div
    className="slider-active slick-initialized slick-slider"
    style={{ background: "#101010" }}
  >
    <button type="button" className="slick-prev slick-arrow" style={{}}>
      <i className="far fa-angle-left" />
    </button>
    <div className="slick-list draggable">
      <div className="slick-track" style={{ opacity: 1 }}>
        <div
          className="single-slider slider-bg d-flex align-items-center slick-slide slick-current slick-active"
          style={{
            backgroundImage: `url(${banner2})`,
            // background: `linear-gradient(to right, rgb(0,0,0,.5), rgb(0,0,0,.5)),url(${banner2})` ,
            backgroundSize: "cover",
            
            position: "relative",
            backgroundPosition:"unset",
            left: 0,
            top: 0,
            zIndex: 999,
            opacity: 1
          }}
          data-slick-index={0}
          aria-hidden="false"
          tabIndex={0}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-7">
                <div className="slider-content s-slider-content mt-80 text-center">
                  <h2
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className="animate__animated animate__bounceIn"
                    style={{ animationDelay: "0.4s" }}
                  >
                    Enjoy A Luxuary Experience
                  </h2>
                  <p
                    data-animation="fadeInUp"
                    data-delay=".6s"
                    className=""
                    style={{ animationDelay: "0.6s" }}
                  >
                   Discover the allure of Sia Resort, crowned the Best Resort in Alibaug. Nestled amidst serene landscapes, our sanctuary offers unparalleled luxury and tranquility. Indulge in exquisite amenities and create timeless memories. Book your escape
                  </p>
                  <div className="slider-btn mt-30 mb-105">
                    <a
                      href="/aboutus"
                      className="btn ss-btn active mr-15"
                      data-animation="fadeInLeft"
                      data-delay=".4s"
                      tabIndex={0}
                      style={{ animationDelay: "0.4s" }}
                    >
                      Discover More{" "}
                    </a>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</section></SwiperSlide>
<SwiperSlide>     <section id="home" className="slider-area fix p-relative">
  <div
    className="slider-active slick-initialized slick-slider"
    style={{ background: "#101010" }}
  >
    <button type="button" className="slick-prev slick-arrow" style={{}}>
      <i className="far fa-angle-left" />
    </button>
    <div className="slick-list draggable">
      <div className="slick-track" style={{ opacity: 1 }}>
        <div
          className="single-slider slider-bg d-flex align-items-center slick-slide slick-current slick-active"
          style={{
            backgroundImage: `url(${banner3})`,
            backgroundSize: "cover",
            
            position: "relative",
            backgroundPosition:"unset",
            left: 0,
            top: 0,
            zIndex: 999,
            opacity: 1
          }}
          data-slick-index={0}
          aria-hidden="false"
          tabIndex={0}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-7">
                <div className="slider-content s-slider-content mt-80 text-center">
                  <h2
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className="animate__animated animate__bounceIn"
                    style={{ animationDelay: "0.4s" }}
                  >
                    Enjoy A Luxuary Experience
                  </h2>
                  <p
                    data-animation="fadeInUp"
                    data-delay=".6s"
                    className=""
                    style={{ animationDelay: "0.6s" }}
                  >
                  Discover the allure of Sia Resort, crowned the Best Resort in Alibaug. Nestled amidst serene landscapes, our sanctuary offers unparalleled luxury and tranquility. Indulge in exquisite amenities and create timeless memories. Book your escape
                  </p>
                  <div className="slider-btn mt-30 mb-105">
                    <a
                      href="/aboutus"
                      className="btn ss-btn active mr-15"
                      data-animation="fadeInLeft"
                      data-delay=".4s"
                      tabIndex={0}
                      style={{ animationDelay: "0.4s" }}
                    >
                      Discover More{" "}
                    </a>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</section></SwiperSlide>
<SwiperSlide>     <section id="home" className="slider-area fix p-relative">
  <div
    className="slider-active slick-initialized slick-slider"
    style={{ background: "#101010" }}
  >
    <button type="button" className="slick-prev slick-arrow" style={{}}>
      <i className="far fa-angle-left" />
    </button>
    <div className="slick-list draggable">
      <div className="slick-track" style={{ opacity: 1 }}>
        <div
          className="single-slider slider-bg d-flex align-items-center slick-slide slick-current slick-active"
          style={{
            backgroundImage: `url(${banner4})`,
            backgroundSize: "cover",
            
            position: "relative",
            backgroundPosition:"unset",
            left: 0,
            top: 0,
            zIndex: 999,
            opacity: 1
          }}
          data-slick-index={0}
          aria-hidden="false"
          tabIndex={0}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-7">
                <div className="slider-content s-slider-content mt-80 text-center">
                  <h2
                    data-animation="fadeInUp"
                    data-delay=".4s"
                    className="animate__animated animate__bounceIn"
                    style={{ animationDelay: "0.4s" }}
                  >
                    Enjoy A Luxuary Experience
                  </h2>
                  <p
                    data-animation="fadeInUp"
                    data-delay=".6s"
                    className=""
                    style={{ animationDelay: "0.6s" }}
                  >
                    Discover the allure of Sia Resort, crowned the Best Resort in Alibaug. Nestled amidst serene landscapes, our sanctuary offers unparalleled luxury and tranquility. Indulge in exquisite amenities and create timeless memories. Book your escape
                  </p>
                  <div className="slider-btn mt-30 mb-105">
                    <a
                      href="/aboutus"
                      className="btn ss-btn active mr-15"
                      data-animation="fadeInLeft"
                      data-delay=".4s"
                      tabIndex={0}
                      style={{ animationDelay: "0.4s" }}
                    >
                      Discover More{" "}
                    </a>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</section></SwiperSlide>
      </Swiper>

    </div>
    
  )
}

export default Slider
