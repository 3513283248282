import React from 'react'
import sea from "../Asset/resort/sea9.webp"
import sea3 from "../Asset/resort/sea.webp"
import sea2 from "../Asset/resort/sea2.webp"
import abbout1 from "../Asset/abbout1.jpg"; 
import Fade from "react-reveal";
import home3 from "../Asset/abbout.jpg";
const Aboutus = () => {
  return (
    <div>
      <section
  className="breadcrumb-area d-flex align-items-center"
  style={{ backgroundImage:  "url(https://r1imghtlak.ibcdn.com/58b6cb385c9311e7887d0a4cef95d023.jpg?downsize=1920:1080)", backgroundPosition:"center",}
}
>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-12 col-lg-12">
        <div className="breadcrumb-wrap text-center">
          <div className="breadcrumb-title">
            <h2>About</h2>
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="about-area about-p pt-120 pb-120 p-relative fix">
  <div className="animations-02">
   
  </div>
  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-6 col-md-12 col-sm-12">
      <Fade left>
        <div
          className="s-about-img p-relative  wow fadeInLeft  animated"
          data-animation="fadeInLeft"
          data-delay=".4s"
          style={{ visibility: "visible", animationName: "fadeInLeft" }}
        >
          <img src={abbout1} alt="img" style={{width:"100%"}} />
          {/* <div className="about-icon">
            <img src="https://htmldemo.zcubethemes.com/riorelax/img/features/about_img_03.png" alt="img" />
          </div> */}
        </div>
        </Fade>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
      <Fade right>
        <div
          className="about-content s-about-content  wow fadeInRight   pl-30 animated"
          data-animation="fadeInRight"
          data-delay=".4s"
          style={{ visibility: "visible", animationName: "fadeInRight" }}
        >
          <div className="about-title second-title pb-25">
            <h5>About Us</h5>
            <h2>Most Safe &amp; Rated Hotel In Alibag.</h2>
          </div>
          <p>
                  Welcome to Sia Resort, the epitome of luxury and relaxation, crowned the Best Resort in Alibaug. Nestled in the heart of serene landscapes, we redefine hospitality with a blend of elegance and comfort. Our commitment lies in providing an 
                  </p>
                  <p>
                  Experience an unforgettable getaway, where every detail is meticulously crafted to surpass your expectations. Whether you crave rejuvenation or adventure, our resort offers an array of amenities and activities tailored to your desires. From exquisite dining to thrilling excursions, immerse yourself in unparalleled luxury. Discover Sia Resort, where every moment is a testament to excellence and the art of hospitality.
                  </p>
          <div className="about-content3 mt-30">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12">
                <ul className="green mb-30">
                <li>
                            {" "}
                            Sun-kissed shores, historic forts, and vibrant local culture beckon.
                          </li>
                          <li>
                            {" "}
                            Serene coastal retreat offering relaxation, adventure, and cultural immersion.
                          </li>
                          <li>
                            {" "}
                            Scenic beauty, seafood delights, and unforgettable memories await visitors.
                          </li>
                </ul>
              </div>
              {/* <div className="col-md-12">
                <div className="signature">
                  <img src="img/features/signature.png" alt="img" />
                  <h3 className="mt-10">Vincent Smith</h3>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        </Fade>
      </div>
    </div>
  </div>
</section>
<section
  id="skill"
  className="skill-area p-relative fix"
  style={{  background: `linear-gradient(to right, rgba(132, 34, 107, 0.5), rgba(132, 34, 107, 0.5)), url(${home3})` }}
>
  <div className="animations-01">
   
  </div>
  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div className="skills-content s-about-content">
          <div className="skills-title pb-20">
            {/* <h5>Sia</h5> */}
            <h2>We Offer Wide Selection of Resort</h2>
          </div>
          <p>
          Explore a diverse array of unforgettable experiences at our resort.
          </p>
          <div className="skills-content s-about-content mt-20">
            <div className="skills">
              <div className="skill mb-30">
                <div className="skill-name">Qulity Food</div>
                <div className="skill-bar">
                  <div
                    className="skill-per"
                    id="80%"
                    style={{ width: "80%" }}
                  />
                </div>
              </div>
              <div className="skill mb-30">
                <div className="skill-name">Maintenance Services</div>
                <div className="skill-bar">
                  <div
                    className="skill-per"
                    id="90%"
                    style={{ width: "90%" }}
                  />
                </div>
              </div>
              <div className="skill mb-30">
                <div className="skill-name">Customer Satisfaction</div>
                <div className="skill-bar">
                  <div
                    className="skill-per"
                    id="70%"
                    style={{ width: "70%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
        <div
          className="skills-img wow fadeInRight   animated"
          data-animation="fadeInRight"
          data-delay=".4s"
          style={{ visibility: "visible", animationName: "fadeInRight" }}
        >
          <img src="https://www.siaresort.com/img/gallery/gallery-1.jpg" alt="img" className="img" />
        </div>
      </div>
    </div>
  </div>
</section>
<section
  className="feature-area2 p-relative fix"
  style={{ background: "#f7f5f1" }}
>
  <div className="animations-02">
   
  </div>
  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
        <div className="feature-img">
        <img src="https://www.siaresort.com/img/accomodation/cozy-1.jpg" alt="img" className="img" style={{width:"948px"}} />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 ">
        <div className="feature-content s-about-content">
          <div className="feature-title pb-20">
            <h5>Luxury &amp; Resort</h5>
            <h2>Experience Sia Resort's allure.</h2>
          </div>
          <p >
               
Experience the captivating allure of Sia Resort, where every moment is an adventure into serenity. Set amidst breathtaking landscapes, our sanctuary of luxury and tranquility eagerly awaits your arrival. From exhilarating outdoor escapades to moments of pure relaxation, immerse yourself in a realm of unparalleled experiences.
                </p>
                <p >
                Our dedicated staff ensures personalized service, catering to your every need with warmth and professionalism. Whether you seek relaxation or excitement, Sia Resort offers the perfect escape. Unwind in elegant accommodations, dine on exquisite cuisine, and explore the beauty of our surroundings. Discover the essence of serenity and rejuvenation at Sia Resort – your ultimate getaway destination.
                </p>
          <div className="slider-btn mt-15">
            <a href="/ROOMS" className="btn ss-btn smoth-scroll">
              Discover More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Aboutus
